import { Spacer } from '~/components/ui/spacer.tsx'
import { StarsRating } from '../../components/ui/stars.tsx'
import { type User, type Review } from '@prisma/client'
import { dateToFriendly } from '~/utils/strings.ts'

interface Props {
  review: Review
  user: User
}

export const ReviewCard = ({ review, user }: Props) => {
  return (
    <div className="relative flex w-full  gap-6 rounded-lg border border-gray-300 p-4">
      <div className="w-full">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-h5">{review.nickname ?? user.firstName}</p>
            <p className="text-sm text-foreground-muted">
              {dateToFriendly(review.createdAt)}
            </p>
          </div>
          <div className="flex flex-col items-end gap-2">
            <StarsRating value={review.stars} />
          </div>
        </div>
        <Spacer size="4xs" />
        <p className="text-foreground-muted">{review.comment}</p>
      </div>
    </div>
  )
}
